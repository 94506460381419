


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MainControls extends Vue {
  isShareScreen = false
  muted = false;
  camHidden = false;

  get disableVideoControls() {
    return this.$store.state.disableVideoControls;
  }

  cameraPreview() {
    this.$emit('cameraPreview');
  }
  muteMic() {
    this.muted = !this.muted;
    this.$emit('muteMic');
  }
  closeVideo() {
    this.$store.dispatch('setVideoCall', false);
  }
  inviteUsers() {
    this.$emit('inviteUsers');
  }
  hideCam() {
    this.camHidden = !this.camHidden;
    this.$emit('hideCam');
  }
}
