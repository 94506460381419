
















/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Prop, Vue } from 'vue-property-decorator';
declare let google: any;

@Component
export default class Map extends Vue {
  @Prop() alertData: any;
  zoom = 15;
  map: any;
  currentLocation?: {lat: number; lng: number};
  myLocation?: {
    latitude: number;
    longitude: number;
  };
  marker: any;
  myLocationMarker: any;
  routeView = false;
  interval: any;
  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({preserveViewport: true});

  mounted() {
    this.initMap();
  }

  initMap(): void {
    this.getMyLocation();
    this.currentLocation = { lat: this.alertData.location.lat, lng: this.alertData.location.long };
    this.map = new google.maps.Map(document.querySelector('#first-responder-map') as HTMLElement, {
      center: this.currentLocation,
      zoom: this.zoom,
    });
    this.directionsRenderer.setMap(this.map);

    this.marker = new google.maps.Marker({
      position: this.currentLocation,
      map: this.map,
      animation: google.maps.Animation.DROP,
      title: 'Caller Location',
    });

    const infowindow = new google.maps.InfoWindow({
      content: 'Caller Location!'
    });

    this.marker.addListener('click', () => {
      infowindow.open(this.map, this.marker);
    });

    this.interval = setInterval(() => {
      this.getMyLocation();
      this.$store.dispatch('rs911/getLocation', this.alertData.code).then(response => {
        this.currentLocation = { lng: response.data.long, lat: response.data.lat };
        if(this.routeView) {
          this.calculateAndDisplayRoute(true);
        } else {
          this.setMarkers();
        }
      });
    }, 15000);
  }

  setMarkers() {
    this.marker.setPosition(new google.maps.LatLng(this.currentLocation?.lat, this.currentLocation?.lng));
    this.map.panTo(new google.maps.LatLng(this.currentLocation?.lat, this.currentLocation?.lng));
    if(this.myLocation) {
      this.myLocationMarker = new google.maps.Marker({
        position: { lat: this.myLocation.latitude, lng: this.myLocation.longitude },
        map: this.map,
        animation: google.maps.Animation.DROP,
        title: 'MyLocation',
      }); 

      const infowindow = new google.maps.InfoWindow({
        content: 'My location!'
      });

      this.myLocationMarker.addListener('click', () => {
        infowindow.open(this.map, this.myLocationMarker);
      });
    }
  }

  calculateAndDisplayRoute(preserveViewport = false) {
    if(!this.myLocation) {
      this.$store.dispatch('notify', {title: 'Error', body: 'Cannot access your location, please enable permissions', variant: 'danger'});
      this.switchToRouteView();
      return;
    }
    this.directionsService.route({
      // origin: new google.maps.LatLng(this.myLocation?.latitude + 0.1, this.myLocation?.longitude + 0.1),
      origin: new google.maps.LatLng(this.myLocation.latitude, this.myLocation.longitude),
      destination: new google.maps.LatLng(this.currentLocation?.lat, this.currentLocation?.lng),
      travelMode: google.maps.TravelMode.DRIVING,
    },
    (response: any, status: any) => {
      if (status === 'OK') {
        this.directionsRenderer.preserveViewport = preserveViewport;
        this.directionsRenderer.setDirections(response);
      } else {
        this.switchToRouteView();
        window.alert('Directions request failed due to ' + status);
      }
    });
  }

  switchToRouteView() {
    this.routeView = !this.routeView;
    if(this.routeView) {
      this.marker.setMap(null);
      this.calculateAndDisplayRoute();
    }
    else {
      this.directionsRenderer.set('directions', null);
      this.marker = new google.maps.Marker({
        position: { lat: this.currentLocation?.lat, lng: this.currentLocation?.lng },
        map: this.map,
        animation: google.maps.Animation.DROP,
        title: 'Caller Location',
      });
      this.map.panTo(new google.maps.LatLng(this.currentLocation?.lat, this.currentLocation?.lng));
      this.map.setZoom(this.zoom);
    }
  }

  getMyLocation() {
    if(!navigator.geolocation) {
      console.log('Geolocation is not supported');
    } else {
      console.log('Locating');
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    }
  }

  success(position: { coords: { latitude: number; longitude: number } }) {
    this.myLocation = position.coords;
  }

  error() {
    console.log('error');
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}
