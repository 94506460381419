















































































































































import { Contact } from '@shared/models/contact';
import { Department } from '@shared/models/api/department';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { phoneFormat } from '@shared/services/validators';
Component.registerHooks(['validations']);

@Component
export default class ContactInvite extends Vue {
  showDepartmentContacts = false;
  timer: number | undefined;
  departmentContacts: any[] = [];
  favoriteContacts: Contact[] | null = null;
  organizations: Department[] = [];
  selectedDepartment: Department|undefined;
  searchString = '';
  form = {
    name: null
  }

  focusInput(event: any) {
    event.target.focus();
  }

  formatPhone(value: string) {
    return phoneFormat(value);
  }

  validations() {
    return {
      form: {
        name: {
        }
      }
    };
  }

  validateState(name: string|number) {
    if(name === 'name' && this.$v.form[name]?.$model?.length > 3 && this.searchString !== this.$v.form[name]?.$model) {
      this.searchString = this.$v.form[name]?.$model;
      clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        this.$store.dispatch('auth/searchSecondPartners', this.$v.form[name]?.$model).then(res => {
          this.organizations = res;
        });
      }, 1200);
    }
  }

  invite(user: Contact | any) {
    console.log('User invited', user);
    this.$store.dispatch('rs911/invite', { users: [{ ssoid: user.ssoid }], callid: this.$store.state.rs911.callId }).then((x) => {
      this.$store.dispatch('notify', {title: 'Contact Invited', body: `Notification for video call sent to ${user.fullname ? user.fullname : user.firstname + ' ' + user.lastname}`, variant: 'success'});
      const depContact = this.departmentContacts.find(item => item.ssoid === user.ssoid);
      const favContact = this.favoriteContacts?.find(item => item.ssoid === user.ssoid);
      if(depContact) depContact.invited = true;
      if(favContact) favContact.invited = true;
    })
    .catch(() => this.$store.dispatch('notify', {title: '', body: 'Error when trying to add contact to favorites', variant: 'danger', position: 'b-toaster-bottom-left'}));
  }

  get callerContacts() {
    return this.$store.state.rs911.callerContacts.filter((x: any) => x.relationship !== 'Caller');
  }

  get callerFromContact() {
    return this.$store.state.rs911.callerContacts.find((x: any) => x.relationship === 'Caller');
  }

  makeFavorite(item: any) {
    const newFavorite = {
      fullname: item.firstname + ' ' + item.lastname,
      ssoid: item.ssoid,
      favorite: true
    };
    this.favoriteContacts?.push(item);
    this.$store.dispatch('contact/createFavorite', newFavorite).then(res => {
      this.$store.dispatch('notify', {title: 'Contact Added', 
      body: `${item.fullname ? item.fullname : item.firstname + ' ' + item.lastname} is added to favorites`, variant: 'success', position: 'b-toaster-bottom-left'});
    });
  }

  removeFromFavorite(item: any) {
    if(!item.id) {
      item.id = this.favoriteContacts?.find(x => x.ssoid === item.ssoid)?.id;
    }
    const index = this.favoriteContacts?.findIndex(x => x.ssoid === item.ssoid);
    if (index != undefined && index > -1) {
      this.favoriteContacts?.splice(index, 1);
    }
    this.$store.dispatch('contact/deleteFavorite', item.id).then(res => {
      this.$store.dispatch('notify', {title: 'Contact Removed', 
      body: `${item.fullname ? item.fullname : item.firstname + ' ' + item.lastname} is removed from favorites`, variant: 'success', position: 'b-toaster-bottom-left'});
    });
  }

  isFavorite(item: any) {
    return this.favoriteContacts?.find(x => x.ssoid === item.ssoid);
  }

  getContactsFromDepartment(id: number) {
    this.selectedDepartment = this.organizations.find(x => x.id === id);
    this.$store.dispatch('rs911/searchFr', { orgid: id, keyword: ''}).then((res) => {
      const data = res.data.filter((x: any) => x.firstname || x.lastname).map((x: any) => {
        return { ...x, invited: false};
      });
      this.departmentContacts = data;
      this.showDepartmentContacts = true;
    })
    .catch(() => this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to get department contacts.', variant: 'danger', position: 'b-toaster-bottom-left'}));
  }

  clearDepartmentContacts() {
    this.showDepartmentContacts = false;
    this.departmentContacts = [];
  }

  getAllFavorite() {
    this.favoriteContacts = null;
    this.$store.dispatch('contact/getAllFavorite').then(res => {
      const data = res.data.map((item: any) => {
        return {...item, invited: false};
      });
      this.favoriteContacts = data;
      console.log('FAVORITE', this.favoriteContacts);
    });
  }

  created() {
    this.getAllFavorite();
  }

}

