













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class UserVideo extends Vue {
  @Prop() cols: any;
  @Prop() idx: any;
  @Prop() track: any;
  @Prop() zoomTrack: any;
  @Prop() mute: any;

  attachedTrack: any;
 
  @Watch('attachedTrack')
  attackedTrackWatch() {
    this.attachedTrack.muted = this.mute;
  }

  switchMain() {
    this.$emit('switchMain', this.track, this.idx ); 
  }

  mounted() {
    this.attachedTrack = this.track.attach();
    (this.$refs.appendVideo as any).appendChild(this.attachedTrack);
  }
}
