








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AllergySeverity } from '@shared/models/enums';
import ShowDetails from '@rs-911/components/ShowDetails.vue';
import { getAge } from '@shared/services/validators';
import FirstResponderInfoList from '@rs-911/components/FirstResponderInfoList.vue';
import { Allergy } from '@shared/models/allergy';
import { Diagnose } from '@shared/models/diagnose';
import { Physician } from '@shared/models/physician';
import { phoneFormat } from '@shared/services/validators';

@Component({
  components: {
    FirstResponderInfoList,
    ShowDetails
  }
})
export default class FirstResponderInfo extends Vue {
  @Prop() alertData: any;
  phoneFormat = phoneFormat;
  locations: any = null;
  allergies: any = null;
  diagnoses: any = null;
  physicians: any = null;
  medications: any = null;
  otherMedicalInfo: any = {
    selected: false
  };
  userInfo: any = null;
  personalInfo: any = null;
  detailsData: any = null;
  showIfTrue = this.resetShowIfTrue();
  selectedAllergy: Allergy | null = null;
  selectedDiagnose: Diagnose | null = null;
  selectedPhysician: Physician | null = null;
  selectedMedication: any | null = null;

  created() {
    const getDate = new Date(this.alertData.personalinfo.dateofbirth.replace(' ', 'T') + ':00');
    const age = getAge(getDate);
    this.personalInfo = {
      Title: 'Personal Info',
      Gender: this.alertData.personalinfo.gender,
      DateOfBirth: getDate.toLocaleDateString() + ' - ('+ age + ')',
      Name: this.alertData.personalinfo.fullname,
      selected: true
    };
    this.detailsData = this.personalInfo;
    if(!this.alertData.medinfo.allergies) return;
    this.allergies = this.alertData.medinfo.allergies.map((x: any) => { return {...x, selected: false};});
    this.diagnoses = this.alertData.medinfo.diagnoses.map((x: any) => { return {...x, selected: false};});
    this.physicians = this.alertData.medinfo.physicians.map((x: any) => { return {...x, selected: false};});
    this.medications = this.alertData.medinfo.medications.map((x: any) => { return {...x, selected: false};});
    this.otherMedicalInfo = {
      text: this.alertData.medinfo.otherMedicalInfo[0].text,
      selected: false
    };
  }

  get videoCall() {
    return this.$store.state.videoCall;
  }

  get disableVideoControls() {
    return this.$store.state.disableVideoControls;
  }

  get date() {
    // parsing date is tricky, firefox fix
    const date = new Date(this.alertData.personalinfo.senton.replace(' ', 'T') + ':00');
    return date.toDateString();
  }

  get time() {
    const date = new Date(this.alertData.personalinfo.senton.replace(' ', 'T') + ':00');
    return date.toLocaleTimeString();
  }

  updateDetails(item: any) {
    this.unSelectAll();
    switch(item.type) {
      case 'other':
        this.detailsData = {
          Title: 'Other Medical Information',
          Text: this.otherMedicalInfo.text,
        };
        this.otherMedicalInfo.selected = true;
        break;
      case 'personalInfo':
        this.detailsData = this.personalInfo;
        this.detailsData.selected = true;
        break;
      case 'allergy':
        this.allergies.find((x: any) => x.name === item.item.name).selected = true;
        this.detailsData = {
          Title: 'Allergy Details',
          Name: item.item.name,
          Severity: AllergySeverity[parseInt(item.item.severity)],
          EpiPenRequired: item.item.epiPenRequired ? 'Yes' : 'No'
        };
        break;
      case 'physician':
        this.physicians.find((x: any) => x.phoneNumber === item.item.phoneNumber).selected = true;
        this.detailsData = {
          Title: 'Physician Details',
          Name: item.item.fullName,
          Specialty: item.item.specialty,
          PhoneNumber: item.item.phoneNumber
        };
        break;
      case 'diagnose':
        this.diagnoses.find((x: any) => x.name === item.item.name).selected = true;
        this.detailsData = {
          Title: 'Diagnose Details',
          Name: item.item.name,
          PhysicianName: item.item.physicianFullName
        };
        break;
      case 'medication':
        this.medications.find((x: any) => x.name === item.item.name).selected = true;
        this.detailsData = {
          Title: 'Medication Details',
          Name: item.item.name,
          Dosage: item.item.dosage,
          PhysicianName: item.item.physicianFullName
        };
        break;
    }
  }

  unSelectAll() {
    this.detailsData.selected = false;
    this.otherMedicalInfo.selected = false;
    this.physicians.forEach((x: any) => {
      x.selected = false;
    });
    this.medications.forEach((x: any) => {
      x.selected = false;
    });
    this.diagnoses.forEach((x: any) => {
      x.selected = false;
    });
    this.allergies.forEach((x: any) => {
      x.selected = false;
    });
  }

  closeVideoCall() {
    this.$store.dispatch('setVideoCall', false);
  }

  openVideoCall() {
    this.$store.dispatch('setVideoCall', true);
    this.$store.dispatch('disableVideoControls', true);
  }

  createItem(type: string) {
    this.showIfTrue = this.resetShowIfTrue();
    switch(type) {
      case 'personalInfo':
        this.showIfTrue.createPersonalInfo = true;
        break;
      case 'allergy':
        this.showIfTrue.createAllergy = true;
        break;
      case 'physician':
        this.showIfTrue.createPhysician = true;
        break;
      case 'diagnose':
        this.showIfTrue.createDiagnose = true;
        break;
      case 'medication':
        this.showIfTrue.createMedication = true;
        break;
    }
  }

  updateItem(x: {type: string; item: any}) {
    this.showIfTrue = this.resetShowIfTrue();
    switch(x.type) {
      case 'allergy':
        this.selectedAllergy = this.allergies.find((allergy: { id: number }) => allergy.id === x.item.id);
        this.showIfTrue.updateAllergy = true;
        break;
      case 'physician':
        this.selectedPhysician = this.physicians.find((phy: { id: number }) => phy.id === x.item.id);
        this.showIfTrue.updatePhysician = true;
        break;
      case 'diagnose':
        this.selectedDiagnose = this.diagnoses.find((diagnose: { id: number }) => diagnose.id === x.item.id);
        this.showIfTrue.updateDiagnose = true;
        break;
      case 'medication':
        this.selectedMedication = this.medications.find((med: { id: number }) => med.id === x.item.id);
        this.showIfTrue.updateMedication = true;
        break;
      case 'info':
        this.showIfTrue.updateInfo = true;
        break;
    }
  }

  resetShowIfTrue() {
    return {
      createAllergy: false,
      updateAllergy: false,
      createDiagnose: false,
      updateDiagnose: false,
      createPhysician: false,
      createPersonalInfo: false,
      updatePhysician: false,
      createMedication: false,
      updateMedication: false,
      updateInfo: false
    };
  }
}
