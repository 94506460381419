























import AppHeader from '@rs-911/components/AppHeader.vue';
import Video from '@rs-911/components/video/Video.vue';
import Map from '@rs-911/components/Map.vue';
import FirstResponderInfo from '@rs-911/components/FirstResponderInfo.vue';
import Logs from '@rs-911/components/Logs.vue';
import ResponderWelcome from '@rs-911/components/forms/ResponderWelcome.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AppHeader,
    Video,
    Map,
    FirstResponderInfo,
    Logs,
    ResponderWelcome
  }
})
export default class FirstResponder extends Vue {
  alertData: any;
  codeEntered = false;

  get showVideo() {
    return this.$store.state.videoCall;
  }

  codeEnteredSuccess(data: any) {
    this.alertData = data;
    this.codeEntered = true;
    console.log('test', this.alertData);
  }

  get callId() {
    return this.$store.state.rs911.callId;
  }

  destroyed() {
    this.$store.commit('rs911/clearCallId');
  }

}
