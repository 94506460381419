



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UserVideo from '@rs-911/components/video/UserVideo.vue';
import MainControls from '@rs-911/components/video/MainControls.vue';
import ContactInvite from '@rs-911/components/video/ContactInvite.vue';
import { Video, connect, createLocalVideoTrack, LocalVideoTrack  } from 'twilio-video';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
gsap.registerPlugin(Draggable);

@Component({
  components: {
    UserVideo,
    MainControls,
    ContactInvite
  }
})
export default class VideoComponent extends Vue {
  @Prop() alertData: any;
  room: any;
  showContactInvite = false;
  localTrack: any;
  mainTrack: any = null;
  screenShareTrack: any;
  zoomTrack: any;
  tracks: any = [];

  get getTracks() {
    return this.tracks();
  }

  beforeDestroy() {
    this.disconnectFromRoom();
    this.$store.dispatch('setVideoCall', false);
  }

  mounted() {
    const videoApp = document.querySelector('.floater');
    const resize = document.querySelector('.js-resize') as any;
    const initialSize = videoApp?.getBoundingClientRect().width;
    Draggable.create(videoApp, {
      type:'x,y',
      trigger: document.querySelector('.controls__top')
    });
		Draggable.create('.js-resize', {
			onDrag: function() {
				gsap.to(videoApp, { width: initialSize + this.x });
      },
      onDragStart: () => {
        resize?.classList.add('d-none');
      },
      onDragEnd: () => {
        resize.style.transform = '';
        resize?.classList.remove('d-none');
      }
		});
  }
  
  created() {
    this.$store.dispatch('rs911/getVideoToken', this.alertData.code.toUpperCase()).then(res => {
      connect(res.data.token, { name: this.alertData.code }).then((room: any) => {
        this.room = room;
        this.cameraPreview();
        console.log('Connected to Room "%s"', room.name);
        room.participants.forEach(this.participantConnected);
        room.on('participantConnected', this.participantConnected);  
        room.on('participantDisconnected', this.participantDisconnected);
        room.on('disconnected', (room: any) => {
          console.log('disconnect', room);
        });
        this.$store.dispatch('disableVideoControls', false);
      }).catch((err: string) => {
        this.errorHandleOnConnect(err);
      });
      }, (error: { message: any}) => {
        // this.errorMsg = `Unable to connect to Room: ${error.message}`;
        console.error(`Unable to connect to Room: ${error.message}`);
        this.errorHandleOnConnect(error.message);
    })
    .catch(err => {
      this.errorHandleOnConnect(err);
    });
  }

  disconnectFromRoom() {
    if(this.room){
      this.room.disconnect();
      this.cameraPreview();
    }
    // this.isConnected = false;
  }

  cameraPreview(){
    if(this.localTrack) {
      this.localTrack.stop();
      this.tracks = this.tracks.filter((item: any) => item.id !== this.localTrack.id);
      this.localTrack = null;
      // this.renderTracks();
    } else {
      createLocalVideoTrack().then((track: any) => {
        this.localTrack = track;
        this.mainTrack = track;
        // setInterval(() => { this.tracks.unshift(track); }, 5000);
        // this.tracks.unshift(track);
      });
    }
  }

  inviteUsers() {
    this.showContactInvite = !this.showContactInvite;
  }
  

  muteMic() {
    this.room.localParticipant.audioTracks.forEach((publication: any) => {
      if (publication.track.isEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
  }

  hideCam() {
    // this.room.localParticipant.publishTrack(this.localTrack);
    // this.room.localParticipant.unpublishTrack(publication.track);
    this.room.localParticipant.videoTracks.forEach((publication: any) => {
      if (publication.track.isEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
  }

  participantConnected(participant: any) {
    console.log('Participant "%s" connected', participant.identity);
  
    participant.on('trackSubscribed', (track: { identity: any }) => {
      track.identity = participant.identity;
      this.tracks.push(track);
    });
    participant.on('trackUnsubscribed', (track: any) => {
      this.tracks = this.tracks.filter((item: any) => item.sid !== track.sid);
      if(track.sid === this.mainTrack.sid) {
        this.mainTrack = this.tracks[0];
        this.tracks.shift();
      }
    });
  }
    
  participantDisconnected(participant: { identity: any }) {
    console.log('Participant "%s" disconnected', participant.identity);
  }

  switchMain(...args: any) {
    const [track, idx] = args;
    const trackIdx = this.tracks.findIndex((x: any) => x.sid === track.sid && x.kind === 'video');
    Vue.set(this.tracks, trackIdx, this.mainTrack);
    this.mainTrack = track;
  }

  errorHandleOnConnect(err: string) {
    this.$store.dispatch('notify', {title: 'Error', body: 'Cannot connect to video. Reason: ' + err, variant: 'danger'});
    this.$store.dispatch('setVideoCall', false);
    this.$store.dispatch('disableVideoControls', false);
  }

  closeVideo() {
    this.$emit('close-video');
  }
}
